import $ from 'jquery'

let Header, MenuBtn, MobileMenu, Body, breakpoint
let scrollPosition

// Menuのリセット
function menuReset() {
	MobileMenu.removeAttr('aria-hidden')
	MobileMenu.css('display', '')
}
function menuBtnReset() {
	MenuBtn.attr('aria-selected', 'false')
	// MobileMenu.css('display','none');
}
// Menuの開閉
function MenuOpen() {
	MobileMenu.attr('aria-hidden', 'false')
	MobileMenu.fadeIn()
}
function MenuClose() {
	MobileMenu.attr('aria-hidden', 'true')
	MobileMenu.fadeOut()
}

// Bodyのclassコントロール
function bodyFixed() {
	scrollPosition = $(window).scrollTop()
	Body.addClass('fixed')
	Body.css({ top: scrollPosition, overflow: 'hidden' })
}
function bodyReset() {
	Body.css({ top: 'auto', overflow: '' })
	Body.removeClass('fixed')
	$('html, body').scrollTop(scrollPosition)
}

export function mobileMenuInit() {
	// ロード時とリサイズ時にリセット
	$(window).on('load', function () {
		menuReset()
		menuBtnReset()
		bodyReset()
	})
	$(window).on('resize', function () {
		menuReset()
		bodyReset()
		menuBtnReset()
	})
}

export function mobileMenuReady() {
	Header = $('.Header')
	MenuBtn = $('.MenuBtn')
	MobileMenu = $('.HeaderNav')
	Body = $('body')
	breakpoint = 930

	// Menu ButtonのARIA属性値
	function changeButtonClose() {
		MenuBtn.attr('aria-selected', 'false')
	}
	function changeButtonOpen() {
		MenuBtn.attr('aria-selected', 'true')
	}

	// Menu ButtonクリックでMenuオープン
	MenuBtn.on('click', function () {
		if ($(this).attr('aria-selected') === 'false') {
			MenuOpen()
			changeButtonOpen()
			Header.addClass('-opened')
			bodyFixed()
		} else {
			bodyReset()
			MenuClose()
			Header.removeClass('-opened')
			changeButtonClose()
		}
	})
}
