import 'lazysizes'
import 'lazysizes/plugins/parent-fit/ls.parent-fit'

import $ from 'jquery'
import { mobileMenuReady, mobileMenuInit } from './includes/HeaderMenu'
import {SimpleParallax} from "./includes/SimpleParallax";
import { Scroll } from './includes/Scroll'

document.addEventListener('lazybeforeunveil', function(e){
    var bg = e.target.getAttribute('data-bg');
    if(bg){
        e.target.style.backgroundImage = 'url(' + bg + ')';
    }
});

$(function () {
    mobileMenuReady();
    SimpleParallax();
    Scroll();
})

mobileMenuInit()